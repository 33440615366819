import { useState, useEffect } from "react";

const Asignaciones = (props) => {
  const [asignacion, setAsignacion] = useState();
  const [legajo, setLegajo] = useState("");
  const [accountLegajo] = useState(props.userData.accountLegajo);
  const [legajoNotFound, setLegajoNotFound] = useState();
  const [selectedLegajo, setSelectedLegajo] = useState();
  let table1 = document.getElementsByClassName("tableAsig")[0];

  // calcula el total de las horas trabajadas devuelve el total de horas.

  //genera un tr

  const submitHandler = (e) => {
    e.preventDefault();
  };

  //re-renders cuando el legajo es cambiado solo si es mas largo que 4 char
  useEffect(() => {
    document.title = "asignaciones - " + props.title;

    let d = new Date();
    let highlightClass = "highlight-asig";

    let items = [];
    let graficList = props.dbData.graficas;
    let queryselector;

    const ClickLegajo = (e) => {
      queryselector = document.querySelectorAll("." + highlightClass);

      for (var i = 0; i < queryselector.length; i++) {
        queryselector[i].classList.remove(highlightClass);
      }
      e.target.parentElement.classList.add(highlightClass);
    };

    const calcHours = (diaAsignado, dia) => {
      dia = graficList[dia]["Dia"].slice(-5);

      dia = dia.split("/");
      dia = dia[1] + "/" + dia[0];
      let totalHoras;
      let dateEntrada = Date.parse(dia + "/" + d.getFullYear() + " " + diaAsignado.horaentrada);

      let dateSalida = Date.parse(dia + "/" + d.getFullYear() + " " + diaAsignado.horasalida);

      if (dateSalida - dateEntrada < 0) dateSalida = dateSalida + 1000 * 60 * 60 * 24;

      totalHoras = (dateSalida - dateEntrada) / (1000 * 60 * 60);
      return totalHoras;
    };

    const dbDataF = (legajo) => {
      //por cada dia empuja un nuevo item a var items.
      for (let dia in graficList) {
        for (let index in graficList[dia]) {
          try {
            if (graficList[dia][index]["legajo"] === legajo) {
              items.push(generateTRs(graficList, dia, index));
            }
          } catch (error) {
            console.error("error");
          }
        }
      }

      //chequea si el legajo tiene horas asignadas
      if (items.length <= 0) {
        setLegajoNotFound(true);
        setSelectedLegajo(legajo);
      } else {
        setLegajoNotFound("");
      }
      setAsignacion(items);

      //añade clase fade in a los items y la remueve en 700ms
      table1.classList.add("fast-fade-in");
      setTimeout(function () {
        table1.classList.remove("fast-fade-in");
        table1.classList.remove("fade-in");
      }, 700);
    };

    const generateTRs = (graficList, dia, index) => {
      let diaAsignado = graficList[dia][index];
      let totalHoras;
      let item;
      let tdClass;

      if (diaAsignado.horaentrada === "OFF") {
        item = (
          <tr onClick={(e) => ClickLegajo(e)} key={graficList[dia]["Dia"]}>
            <td>{graficList[dia]["Dia"]}</td>
            <td>OFF</td>
            <td>OFF</td>
            <td>0</td>
          </tr>
        );
        return item;
      }
      totalHoras = calcHours(diaAsignado, dia);
      if (totalHoras < 5.5) tdClass = "";
      if (totalHoras > 5.5) tdClass = "plus-five-and-half";
      if (totalHoras > 8) tdClass = "plus-eight";

      item = (
        <tr onClick={(e) => ClickLegajo(e)} key={graficList[dia]["Dia"]}>
          <td>{graficList[dia]["Dia"]}</td>
          <td>{diaAsignado.horaentrada}</td>
          <td>{diaAsignado.horasalida}</td>
          <td className={tdClass}>{totalHoras}</td>
        </tr>
      );
      setSelectedLegajo(diaAsignado.legajo);
      return item;
    };
    if (legajo.legajo && legajo.legajo.length > 4) {
      dbDataF(legajo.legajo);
    }
  }, [legajo, props.dbData.graficas, table1, props.title]);

  //cuando carga el fetch de app.js re-render la pagina para cargar el legajo de la cuenta
  useEffect(() => {
    setLegajo({ legajo: accountLegajo });
  }, [props.dbData, accountLegajo]);

  return (
    <div className="asignaciones-cont">
      <div className="divpretable asig">
        <form className="inputLegajo " onSubmit={submitHandler}>
          <input
            className="pretable-input"
            autoFocus
            onChange={(e) => setLegajo({ ...legajo, legajo: e.target.value })}
            type="text"
          />
          <label>Legajo {selectedLegajo}</label>
          {/* <span>{selectedLegajo}</span> */}
        </form>
      </div>

      <table className="tableAsig fade-in">
        {!legajoNotFound && (
          <thead>
            <tr>
              <th>Dia</th>
              <th>Entrada</th>
              <th>Salida</th>
              <th>Horas</th>
            </tr>
          </thead>
        )}
        <tbody>{asignacion}</tbody>
      </table>

      {legajoNotFound && (
        <div className="legajoNotFound">
          <h3>
            Legajo numero <span>{selectedLegajo}</span> <br /> No encontrado <br />
          </h3>
          <br />
          <h4>
            Este legajo no se encuentra en la base de datos de <span>{props.userData.local}</span> si este legajo
            pertenece a alguna persona en este local, avisame{" "}
            <span>
              <a href="mailto:soporte@asignaciones.com.ar">soporte@asignaciones.com.ar</a>
            </span>
          </h4>
        </div>
      )}
    </div>
  );
};

export default Asignaciones;
