import { useEffect, useState } from "react";
import Modifications from "./subcomponents/Modifications";
import ConteoSelect from "./subcomponents/ConteoSelect";
import SueldoAprox from "./subcomponents/SueldoAprox";
const Conteo = (props) => {
  const [quincena, setQuincena] = useState();
  const [mes, setMes] = useState();
  const [data, setData] = useState();
  const [selectedQuicenaMEs, setSelectedQuicenaMEs] = useState();
  const [fetchingData, setFetchingData] = useState();
  const [fetchingSelect, setFetchingSelect] = useState();
  const [loadingClass, setLoadingClass] = useState();
  const [quincenaNotFound, setQuincenaNotFound] = useState();
  const [globalHoras, setGlobalHoras] = useState();
  const [selectionObj, setSelectionObj] = useState({ entrada: "00:00", salida: "00:00" });
  const [update, setUpdate] = useState(0);
  let dropDowndirection;
  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  useEffect(() => {
    document.title = "Conteo - " + props.title;
  }, [props.title]);
  useEffect(() => {
    if (fetchingSelect === true || fetchingData === true) {
      setLoadingClass(true);
    } else {
      setLoadingClass(false);
    }
  }, [fetchingData, fetchingSelect]);

  useEffect(() => {
    let trList = [];

    const funcTr = (
      dia,
      horaentrada,
      horasalida,
      totalHoras,
      mHoraentrada,
      mHorasalida,
      oHoraentrada,
      oHorasalida,
      tdClass,
      classModify
    ) => {
      return (
        <tr
          className={classModify}
          onClick={() =>
            hourModify(dia, horaentrada, horasalida, totalHoras, mHoraentrada, mHorasalida, oHoraentrada, oHorasalida)
          }
          key={dia}
        >
          <td className="td-dia">{dia}</td>
          <td className="td-hora">{horaentrada}</td>
          <td className="td-hora">{horasalida}</td>

          <td className={tdClass}>{totalHoras}</td>
        </tr>
      );
    };

    const selectGrafica = (data) => {
      let tr;
      var totalHoras;
      let d = new Date();
      let totalConteo = 0;

      for (let index in data) {
        let dia = data[index].dia;

        let horaentrada;
        let horasalida;

        let oHoraentrada = data[index].horaentrada;
        let oHorasalida = data[index].horasalida;
        let mHoraentrada = data[index].mhoraentrada;
        let mHorasalida = data[index].mhorasalida;
        let classModify = "";
        if (mHoraentrada !== null) {
          horaentrada = mHoraentrada;
          horasalida = mHorasalida;
          classModify = "horario-modificada";
        } else {
          horaentrada = oHoraentrada;
          horasalida = oHorasalida;
          classModify = "";
        }

        if (!dia) {
          continue;
        }
        dia = dia.slice(-5);
        dia = dia.split("/");
        dia = dia[1] + "/" + dia[0];

        if (horaentrada === "OFF") {
          totalHoras = 0;
        } else {
          let dateEntrada = Date.parse(dia.slice(-5) + "/" + d.getFullYear() + " " + horaentrada);

          let dateSalida = Date.parse(dia.slice(-5) + "/" + d.getFullYear() + " " + horasalida);
          if (dateSalida - dateEntrada < 0) {
            dateSalida = dateSalida + 1000 * 60 * 60 * 24;
          }

          totalHoras = (dateSalida - dateEntrada) / (1000 * 60 * 60);
          totalHoras = Number(totalHoras.toFixed(2));
        }

        let tdClass;
        if (totalHoras < 5.5) tdClass = "";
        if (totalHoras >= 5.5) tdClass = "plus-five-and-half";
        if (totalHoras >= 8) tdClass = "plus-eight";

        tr = funcTr(
          data[index].dia,
          horaentrada,
          horasalida,
          totalHoras,
          mHoraentrada,
          mHorasalida,
          oHoraentrada,
          oHorasalida,
          tdClass,
          classModify
        );
        totalConteo += totalHoras;

        trList.push(tr);
      }
      setGlobalHoras(totalConteo);
      setSelectedQuicenaMEs(trList);
    };
    if (data) {
      selectGrafica(data);
    }
  }, [data]);

  useEffect(() => {
    const getQuincena = () => {
      setFetchingData(true);

      let data = {
        mes: mes,
        quincena: quincena,
        local: props.user.local,
        user: props.user,
      };
      fetch(props.mainURL+"/get_quincena.php", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
        .then((res) => {
          setFetchingData(false);
          if (!res.ok) {
            throw Error("fail to fetch data from resource");
          }

          return res.json();
        })
        .then((data) => {
          if (data === "quincenaNoExistente") {
            sleep(5000);
            setQuincenaNotFound(true);
            return;
          }

          setQuincenaNotFound(false);
          setFetchingData(false);
          setData(data);
        })
        .catch((err) => {
          setFetchingData(false);
          console.log(err);
        });
    };

    if (mes && quincena) {
      getQuincena();
    }
  }, [mes, quincena, props.user, update,props.mainURL]);

  if (window.innerWidth <= "780") dropDowndirection = "top";

  // const select = "";
  const quincenaRename = (number) => {
    if (quincena === "1") {
      return <span>primer</span>;
    } else {
      return <span>segunda</span>;
    }
  };
  const spanModifToggle = () => {
    window.scrollTo(0, 0);
    document.getElementById("modalModif").classList.toggle("d-none");
    document.getElementsByTagName("html")[0].classList.toggle("disable-scroll");
  };

  const ModalModif = () => (
    <div id="modalModif" onClick={() => spanModifToggle()} className="modal modif-horarios-modal  d-none">
      <div className="modal-content text-center">
        <div className="sub-modal">
          <h3 className="content-section">Al tocar un horario se abrira el menu de modificacion</h3>
        </div>
      </div>
    </div>
  );

  const hourModify = (dia, entrada, salida, total, mHoraentrada, mHorasalida, oHoraentrada, oHorasalida) => {
    setSelectionObj({ dia, entrada, salida, total, mHoraentrada, mHorasalida, oHoraentrada, oHorasalida });
    document.getElementById("hour-modify").classList.toggle("d-none");
  };

  const showSueldoAprox = () => {
    window.scrollTo(0, 0);
    document.getElementById("sueldo-aprox").classList.toggle("d-none");
    document.getElementsByTagName("html")[0].classList.toggle("disable-scroll");
  };

  return (
    <div>
      <ModalModif />
      <SueldoAprox user={props.user} mes={mes} quincena={quincena} mainURL={props.mainURL} />

      <Modifications
        setUpdate={setUpdate}
        update={update}
        user={props.user}
        mes={mes}
        quincena={quincena}
        selectionObj={selectionObj}
        mainURL={props.mainURL}
      />
      <div className={loadingClass ? "loading-conteo show" : "loading-conteo hide"}>
        <div className="container-conteo">
          <div className="loading__letter">c</div>
          <div className="loading__letter">a</div>
          <div className="loading__letter">r</div>
          <div className="loading__letter">g</div>
          <div className="loading__letter">a</div>
          <div className="loading__letter">n</div>
          <div className="loading__letter">d</div>
          <div className="loading__letter">o</div>
          <div className="loading__letter">.</div>
          <div className="loading__letter">.</div>
        </div>
      </div>
      <div className="divpretable">
        <ConteoSelect
          quincenaDiasList={props.quincenaDiasList}
          setMes={setMes}
          setQuincena={setQuincena}
          dropDowndirection={dropDowndirection}
          setFetchingSelect={setFetchingSelect}
        />
      </div>
      {!quincenaNotFound && (
        <div className="tableAsig fade-in">
          {globalHoras ? (
            <div>
              <div className="d-flex">
                <h4 className="ml-auto d-inline  basic-button bg-secondary" onClick={() => spanModifToggle()}>
                  Modificar
                </h4>
                <h4 className="d-inline ml-1 basic-button bg-secondary" onClick={() => showSueldoAprox()}>
                  Sueldo aprox
                </h4>
              </div>
              <div id="horasTotales">
                <h3>Horas totales: {globalHoras}</h3>
              </div>
            </div>
          ) : (
            <div id="horasTotales">
              <h3>Horas totales: 0</h3>
            </div>
          )}
          <table>
            <thead>
              <tr key="head">
                <th>Dia</th>
                <th>Entrada</th>
                <th>Salida</th>
                <th>Horas</th>
              </tr>
            </thead>
            <tbody>{selectedQuicenaMEs}</tbody>
          </table>
        </div>
      )}
      {quincenaNotFound && !fetchingData && (
        <div className="legajoNotFound">
          <h3>
            la <span>{quincenaRename(quincena)}</span> quincena del <span>{mes}</span> <br />
            No fue encontrada
            <br />
          </h3>
          <br />
          <h4>
            Ya hay creadas graficas de esta quincena en
            <span> {props.user.local}</span>? si esta quincena ya tiene dias asignados, avisame{" "}
            <span>
              <a href="mailto:soporte@asignaciones.com.ar">soporte@asignaciones.com.ar</a>
            </span>
          </h4>
        </div>
      )}
    </div>
  );
};

export default Conteo;
