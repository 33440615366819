import React from "react";

export default function Updates(props) {
  return (
    <div id="updates">
      <div className="announcement bg-striped-green">
        <div className="bg-white-alpha another">
          <h3>Actualizacion:</h3>

          <div className="show-hidden-cont">
            <span className="title-versions">
              V 3.4{" "}
              <span
                onClick={(e) => props.hideUpdateDetails("v3.4", e)}
                className="button-medium bg-black  ml-1 border-radius-r-1"
              >
                Mostrar mas
              </span>
            </span>
            <ul id="v3.4" className="shown hidden mt-2">
              Novedades:
              <li>Cafecitos no redirecciona a la pagina / aplicacion de mercado pago.</li>
            </ul>
            <br />
          </div>
          <div className="show-hidden-cont">
            <span className="title-versions">
              V 3.3{" "}
              <span
                onClick={(e) => props.hideUpdateDetails("v3.3", e)}
                className="button-medium bg-black  ml-1 border-radius-r-1"
              >
                Mostrar mas
              </span>
            </span>
            <ul id="v3.3" className="shown hidden mt-2">
              Novedades:
              <li>Agregado "Cafecitos", sistema de donaciones para el servicio.</li>
              <br />
              <hr />
              <br />
              Problemas solucionados:
              <li>Alineación de objetos en la pantalla de inicio de sesión</li>
            </ul>
            <br />
          </div>

          <div className="show-hidden-cont">
            <span className="title-versions">
              V 3.2{" "}
              <span
                onClick={(e) => props.hideUpdateDetails("v3.2", e)}
                className="button-medium bg-black  ml-1 border-radius-r-1"
              >
                Mostrar mas
              </span>
            </span>
            <ul id="v3.2" className="shown hidden mt-2">
              Problemas solucionados:
              <li>
                Cuando las asignaciones(alsea) dejaban de funcionar, la página dejaba de mostrar los horarios. De ahora
                en mas solo se mostrará un signo de exclamación en la esquina superior derecha.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
