import { useState } from "react";
import Modalpay from "./Modalpay";
import Amount from "./Amount";
import imgpc1 from "../../../img/pc/1.png";
import imgpc2 from "../../../img/pc/2.png";
import imgpc3 from "../../../img/pc/3.png";
import imgmobile1 from "../../../img/mobile/1.png";
import imgmobile2 from "../../../img/mobile/2.png";
import imgmobile3 from "../../../img/mobile/3.png";
import { useHistory } from "react-router";
export default function StartPageCafecito(props) {
  let history = useHistory();
  const [value, setValue] = useState();
  const [modalToggler, setModalToggler] = useState(false);
  const [showInstructions, setShowInstructions] = useState(false);

  const buttonGenerator = (id, value) => {
    window.scrollTo(0, 0);
    setValue(value);
    let paymentDiv = document.getElementById("paymentDiv");
    paymentDiv.innerHTML = "";
    let paymentModal = document.getElementById("paymentModal");
    paymentModal.classList.remove("d-none");
    const script = document.createElement("script");
    let payInnerText = "Pagar cafecito por $" + value;
    script.src = "https://www.mercadopago.com.ar/integrations/v1/web-payment-checkout.js";
    script.setAttribute("data-preference-id", id);
    script.setAttribute("data-source", "button");
    script.setAttribute("data-button-label", payInnerText);
    script.async = true;
    paymentDiv.appendChild(script);
  };
  const redirectToSuccess = (e) => {
    e.preventDefault();
    
    console.log(e.target[0].value);
    history.push("/success?payment_id="+e.target[0].value)
  };
  const closeModal = (e) => {
    if (e.target.id === "cafecito-modal") {
      document.getElementsByTagName("html")[0].classList.remove("disable-scroll");
      setModalToggler(false);
    }
  };

  const showModalCafecitos = () => {
    window.scrollTo(0, 0);
    setModalToggler(true);
    document.getElementsByTagName("html")[0].classList.add("disable-scroll");
  };

  const ModalCafecitos = () => {
    return (
      <div id="cafecito-modal" onClick={closeModal} className="modal">
        <div className="modal-content">
          <h3 className="title-modal">Ingresar nombre/nick a la lista</h3>
          <div className="content-section">
            
              Para ingresar tu nick a la lista ingresa la id de tu pago{" "}
              <form onSubmit={(e) => redirectToSuccess(e)}>
                <input className="d-inline-block input-short text-left" type="text" />
                <button className="button-small bg-black">Ingresar</button>
              </form>
          

            <p>
              Como encontrar tu id de pago:{" "}
              <button className="button-small bg-black" onClick={() => setShowInstructions("pc")}>
                En pc
              </button>
              <button className="button-small bg-black ml-1" onClick={() => setShowInstructions("mobile")}>
                En celular
              </button>
            </p>
            {showInstructions === "mobile" && <HowToFindPaymentidOnMobile />}
            {showInstructions === "pc" && <HowToFindPaymentidOnPc />}
          </div>
        </div>
      </div>
    );
  };

  const HowToFindPaymentidOnPc = () => {
    return (
      <div>
        <div className="bg-gray p-1 mt-2">
          <p className=" mb-1">Ingresa a la pagina de mercadopago e ingresa "Cafecito" en el cuadro de busqueda</p>
          <img src={imgpc1} className="img-w-100" alt="" />
        </div>

        <div className="bg-gray p-1 mt-2">
          <p className="mb-1">Te va a aparecer el listado de compras que hiciste con ese termino.</p>
          <img src={imgpc2} className="img-w-100" alt="" />
        </div>

        <div className="bg-gray p-1 mt-2">
          <p className="mb-1">
            Ingresa al que corresponda y hace click en la id de compra (se va a copiar automaticamente)
          </p>
          <img src={imgpc3} className="img-w-100" alt="" />
        </div>
      </div>
    );
  };
  const HowToFindPaymentidOnMobile = () => {
    return (
      <div>
        <div className="bg-gray p-1 mt-2">
          <p className=" mb-1">Ingresa a la app de mercadopago e ingresa a "ver toda tu actividad"</p>
          <img src={imgmobile1} className="img-w-100" alt="" />
        </div>

        <div className="bg-gray p-1 mt-2">
          <p className="mb-1">busca "Cafecito".</p>
          <img src={imgmobile2} className="img-w-100" alt="" />
        </div>

        <div className="bg-gray p-1 mt-2">
          <p className="mb-1">Ingresa al que corresponda y toca en la id de compra (se va a copiar automaticamente)</p>
          <img src={imgmobile3} className="img-w-100" alt="" />
        </div>
      </div>
    );
  };
  return (
    <div>
      {modalToggler && <ModalCafecitos />}
      <Modalpay value={value} />
      <div className="announcement bg-striped-green">
        <div className="bg-white-alpha another show-hidden-cont">
          <h3> Si queres apoyar el desarrollo de la aplicación podés comprarme un cafecito c:</h3>

          <div>
            <span
              className="button-big bg-primary d-block text-center"
              onClick={(e) => props.hideUpdateDetails("cafecito-more", e, "Donaciones", "Mostrar menos")}
            >
              Donaciones
            </span>
            <div id="cafecito-more" className="shown hidden">
              <Amount buttonGenerator={buttonGenerator} />
              <p className="pl-1 pt-1 ">
                Llevo 2 años mas o menos con este proyecto, Pensé varias veces en añadir publicidad pero la verdad es
                que no me gusta el sistema de publicidad en aplicaciones o WEB, asi que nada, si querés apoyar el
                proyecto y darme ganas de seguir mejorandolo, podés comprarme un cafecito, Gracias!
              </p>
            </div>
          </div>
          <p className="small">
            (si ya compraste un cafecito y queres que tu nombre/nick aparezca en los reconocimientos de arriba,{" "}
            <button onClick={() => showModalCafecitos()} className="button-small bg-primary">
              hace click aca
            </button>{" "}
            )
          </p>
          <br />
        </div>
      </div>
    </div>
  );
}
