import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

//https://asignaciones.com.ar/success?collection_id=17791180897&collection_status=approved&payment_id=17791180897&status=approved&external_reference=null&payment_type=debit_card&merchant_order_id=3486445453&preference_id=235896449-fcc76017-8bec-4733-88fd-a3e4eb1b636e&site_id=MLA&processing_mode=aggregator&merchant_account_id=null
export default function Success(props) {

  const [isAuthentic, setIsAuthentic] = useState("loading");
  const [fetchData, setFetchData] = useState(false);
  const [username, setUsername] = useState({ user: "" });
  const [error, setError] = useState(false);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const payment_id = urlParams.get("payment_id");
  const [id, setId] = useState(payment_id);
  const [insertedToDb, setInsertedToDb] = useState();

  const submitHandler = (e) => {
    e.preventDefault();
    sendData({ username: username.user, email: fetchData[1], id: payment_id, amount: fetchData[2] });
  };

  const sendData = (data) => {
    fetch("https://api.asignaciones.com.ar/cafecito_api.php?insertToDb=true", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ data }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data === "success") {
          setInsertedToDb(true);
        }
        errorHandling(data);
      });
  };

  useEffect(() => {
    const checkTransactionId = (id) => {
      console.log(id)
      JSON.stringify({ id })
      fetch("https://api.asignaciones.com.ar/cafecito_api.php?checkid=true", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ id }),
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setIsAuthentic(data[0]);
          setFetchData(data);
        })
        .catch((err) => {
          console.error(err);
        });
    };
    checkTransactionId(id);
  }, [id, payment_id]);

  const redirectToSuccess = (e) => {
    e.preventDefault();

    console.log(e.target[0].value);
    setId(e.target[0].value);
    // history.push("/success?payment_id=" + e.target[0].value);
  };

  const errorHandling = (data) => {
    if (data === "success") {
      setError(false);
    } else if (data === "buyerid already used") {
      setError("El sistema detecto que este cafecito ya tiene un nombre/nick asignado.");
    } else {
      setError("Hubo un error.");
    }
  };
  const Content = () => {
    let obj = (
      <div>
        <h3 className="mb-2">
          Gracias! aca abajo podes ingresar un nombre/nick para que se muestre en los reconocimientos de la gente que
          compro cafecitos para la pagina. (En caso de nombre solo se mostrara la primer letra del apellido)
        </h3>
        <div className="input-refresh-div text-center">
          <form id="test" onSubmit={submitHandler}>
            <input
              id="input-name"
              key="111"
              placeholder="Nombre|Nick"
              value={username.user}
              onChange={(e) => setUsername({ user: e.target.value })}
              className="change-name"
              type="text"
              autoFocus
            />

            <input type="submit" className="refresh" value="Enviar datos" />
            {/* <p>data {username.user}</p> */}
          </form>
          <h4 className="mt-2 d-inline-block">Mail vinculado: {fetchData[1]}</h4>
        </div>
        <h2 className="text-center mt-2"> Gracias por el cafecito!</h2>
      </div>
    );
    return obj;
  };
  const Fail = () => {
    return (
      <div className="text-center">
        <p>
          Comproba que tu id de pago sea correcta: <span className="bg-gray">{payment_id}</span>
        </p>
        <p>Si ingresaste mal tu id podes reintentar aca: </p>
        <form onSubmit={(e) => redirectToSuccess(e)}>
          <input className="d-inline-block input-short text-left" type="text" />
          <button className="button-small bg-black">Ingresar</button>
        </form>
        <p className="mt-2">
          Hubo algun problema con el registro de tu pago, si se desconto el dinero de tu cuenta, envia un mail a{" "}
          <a href="mailto:soporte@asignaciones.com.ar">soporte@asignaciones.com.ar</a>
        </p>
      </div>
    );
  };
  const Succeded = () => {
    return (
      <div className="text-center">
        Tu nombre/nick fue añadido a la lista de nombres reconocidos, muchas gracias!
        <Link to="/" className="button-medium mt-2 d-inline-block bg-primary">
          Volver al inicio
        </Link>
      </div>
    );
  };
  const Loading = () => {
    return <div className="text-center">Cargando confirmacion de mercado pago, espera un momento.</div>;
  };
  const Error = () => {
    return (
      <div className="text-center">
        <p>Hubo un problema al intentar ingresar tus datos al sistema:</p>
        <p>{error}</p>
        <br />
        <p>Muchas gracias por el cafecito!!!</p>
        <Link to="/" className="button-medium mt-2 d-inline-block bg-primary">
          Volver al inicio
        </Link>
      </div>
    );
  };
  return (
    <div id="success-cafecito">
      {isAuthentic === "loading" && !error && !insertedToDb && <Loading />}
      {isAuthentic === "success" && !error && !insertedToDb && <Content />}
      {isAuthentic === "fail" && !error && !insertedToDb && <Fail />}
      {error && <Error />}
      {insertedToDb && <Succeded />}
    </div>
  );
}
