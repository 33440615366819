import { useEffect } from "react";
import StartPageCafecito from "../../global/cafecito/StartPageCafecito";
import Updates from "./subcomponents/Updates";
const Start = (props) => {
  useEffect(() => {
    document.title = "Inicio - asignaciones by thtst";
  }, []);



  return (
    <div>
      <div className="startNews">
        <h2>ASIGNACIONES.AR.NF {props.version}</h2>
        <br />
        <StartPageCafecito hideUpdateDetails={props.hideUpdateDetails} />
        <br />
        <Updates hideUpdateDetails={props.hideUpdateDetails} />
        <br />

        <div className="announcement bg-striped-green">
          <div className="bg-white-alpha another">
            <h3>Funcionamiento:</h3>
            <p>
              ASIGNACIONES.AR.NF Trabaja en base a la app de Mis asignaciones. Esta página recopila la información de la
              app legajo por legajo y la guarda en su propia base de datos, Si el servidor de la app no funciona, esta
              página no será capaz de actualizar los datos de las gráficas.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Start;
