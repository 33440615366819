import { useEffect, useState, useCallback, React } from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { delete_cookie } from "sfcookies";
import Cookie from "universal-cookie";

import Start from "./components/pages/post-login/Start";
import NavBar from "./components/global/NavBar";
import LoginForm from "./components/pages/pre-login/LoginForm";
import Asignaciones from "./components/pages/post-login/Asignaciones";
import Grafica from "./components/pages/post-login/Grafica";
import Conteo from "./components/pages/post-login/Conteo";
import ModalUser from "./components/pages/post-login/subcomponents/ModalUser";
import StartPage from "./components/pages/pre-login/StartPage";
import NavBarStart from "./components/pages/pre-login/NavBarStart";
import Success from "./components/cafecito/Success";
import Header from "./components/global/Header";
import Downfor from "./Downfor";

const mainTitle = "Asignaciones by thtst ";
function App() {
  const version = "(v3.5)";
  const mainURL = "https://api.asignaciones.com.ar"
  var globalCookie = new Cookie();
  const [maintenance, setMaintenance] = useState();
  const [isAlseaDown, setIsAlseaDown] = useState();
  const [isFirstRender, setIsFirstRender] = useState();
  const [user, setUser] = useState({
    name: globalCookie.get("username"),
    id: globalCookie.get("userid"),
    local: globalCookie.get("local"),
    logged: globalCookie.get("logged", { doNotParse: false }),
    accountLegajo: globalCookie.get("accountLegajo"),
    startPage: globalCookie.get("startPage"),
  });

  const [dbData, setDbData] = useState({
    diasList: false,
    graficas: false,
  });

  const [quincenaDiasList, setQuincenaDiasList] = useState();

  const [loading, setLoading] = useState();

  const sendResetPassword = () => {
    console.log("resetPasword");
  };

  const getDbData = useCallback(() => {
    setLoading(true);
    setDbData({
      diasList: true,
      graficas: true,
    });
    fetch(mainURL+"/data_from_db2.php", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ local: user.local, user: user }),
    })
      .then((res) => {
        if (!res.ok) {
          setLoading(false);
          throw Error("fail to fetch data from resource");
        }

        return res.json();
      })
      .then((data) => {
        if (data === "authenticationFailed") {
          logout();
        } else {
          let tempDiasList = data.diasList;
          delete data.diasList;

          setDbData({
            diasList: tempDiasList,
            graficas: data,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [user]);

  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  const getQuincenalist = () => {
    setQuincenaDiasList("fetch");
    fetch(mainURL+"/get_quincena_list.php", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setQuincenaDiasList(data);
      })
      .catch((err) => {});
  };

  const logout = () => {
    delete_cookie("userid");
    delete_cookie("local");
    delete_cookie("username");
    delete_cookie("logged");
    delete_cookie("accountLegajo");
    setUser({ name: "", id: "", local: "", logged: false });
    delete_cookie("startPage");
    setModalToggler(undefined);
  };

  const hideUpdateDetails = (id, e, pre = "Mostrar mas", pos = "Mostrar menos", delay = 300, modifyID = null) => {
    let element = document.getElementById(id);
    if (modifyID) {
      e = document.getElementById(modifyID);
    } else {
      e = e.target;
    }

    if (element.classList.contains("hidden")) {
      sleep(delay).then(() => {
        e.innerHTML = pos;
      });
    } else {
      sleep(delay).then(() => {
        e.innerHTML = pre;
      });
    }
    element.classList.toggle("hidden");
  };

  useEffect(() => {
    if (!dbData.diasList) {
      try {
        if (user.local.length !== 0) {
          getDbData();
        }
      } catch {}
    }
    if (!quincenaDiasList) {
      getQuincenalist();
    }
  });
  useEffect(() => {
    fetch(mainURL+"/data_from_db.php?isAlseaDown", {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("fail to fetch data from resource");
        }

        return res.json();
      })
      .then((data) => {
        setIsAlseaDown(data);
      })
      .catch((err) => {
        console.log(err);
      });
    fetch(mainURL+"/data_from_db.php?maintenance", {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("fail to fetch data from resource");
        }

        return res.json();
      })
      .then((data) => {
        if (data === "maintenance") {
          setMaintenance(true);
          console.log(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    try {
      if (user.local.length !== 0) {
        getDbData();
      }
    } catch {}
  }, [user.local, getDbData]);

  const [modalToggler, setModalToggler] = useState();

  const mainStructure = () => (
    <div className="App" id="body">
      <Header mainURL={mainURL} version={version} hideUpdateDetails={hideUpdateDetails} />
      {user.logged === true ? (
        <div>
          <div className={loading ? "loading show" : "loading hide"}>
            <div className="container">
              <div className="container-child">
                <div className="loading__letter">A</div>
                <div className="loading__letter">s</div>
                <div className="loading__letter">i</div>
                <div className="loading__letter">g</div>
                <div className="loading__letter">n</div>
                <div className="loading__letter">a</div>
                <div className="loading__letter">c</div>
                <div className="loading__letter">i</div>
                <div className="loading__letter">o</div>
                <div className="loading__letter">n</div>
                <div className="loading__letter">e</div>
                <div className="loading__letter">s</div>
              </div>
              <div id="byTHTST-loading">
                <p>By THTST</p>
              </div>
            </div>
          </div>
          <div className={loading ? "hide-all-loading" : "show-all-notloading"}>
            {isAlseaDown && (
              <div id="alsea-down">
                <i className="fas fa-exclamation-circle"></i>
              </div>
            )}
            <Router>
              <NavBar
                modalToggler={modalToggler}
                setModalToggler={setModalToggler}
                logout={logout}
                isFirstRender={isFirstRender}
                setIsFirstRender={setIsFirstRender}
                user={user}
              />

              {modalToggler && (
                <ModalUser
                  sendResetPassword={sendResetPassword}
                  user={user}
                  logout={logout}
                  setModalToggler={setModalToggler}
                />
              )}

              <div className="content">
                <Switch>
                  <Route path="/asignaciones">
                    <Asignaciones title={mainTitle} dbData={dbData} userData={user} />
                  </Route>
                  <Route path="/graficas">
                    <Grafica title={mainTitle} dbData={dbData} userData={user} />
                  </Route>
                  <Route path="/conteo">
                    <Conteo
                      title={mainTitle}
                      user={user}
                      dbData={dbData}
                      userData={user}
                      quincenaDiasList={quincenaDiasList}
                      mainURL={mainURL}
                    />
                  </Route>
                  <Route path="/success">
                    <Success title={mainTitle} islogged={true} userData={user} mainURL={mainURL}/>
                  </Route>
                  <Route exact path="/">
                    <Start title={mainTitle} hideUpdateDetails={hideUpdateDetails} version={version} />
                  </Route>
                  <Route>
                    <Redirect to="/"></Redirect>
                  </Route>
                </Switch>
              </div>
            </Router>
          </div>
        </div>
      ) : (
        <div className="app" id="body">
          <div className="content">
            {isAlseaDown && (
              <div id="alsea-down">
                <i className="fas fa-exclamation-circle"></i>
              </div>
            )}
            <Router>
              <NavBarStart></NavBarStart>
              <Switch>
                <Route path="/success">
                  <Success userData={user} islogged={false} />
                </Route>
                <Route path="/login">
                  <LoginForm mainURL={mainURL} hideUpdateDetails={hideUpdateDetails} title={mainTitle} setUser={setUser} />
                </Route>
                <Route exact path="/">
                  <StartPage mainURL={mainURL} title={mainTitle} hideUpdateDetails={hideUpdateDetails} />
                </Route>
                <Route>
                  <Redirect to="/"></Redirect>
                </Route>
              </Switch>
            </Router>
          </div>
        </div>
      )}
    </div>
  );

  if (maintenance && window.location.hostname !== "localhost") {
    return <Downfor />;
  } else {
    return mainStructure();
  }
}

export default App;
