import React from "react";

export default function Amount({ buttonGenerator, hideUpdateDetails }) {
  return (
    <div className="text-center bg-striped-white">
      <h3>
        Comprame un cafecito!
        <br /> (Mercado Pago)
      </h3>

      <div id="dropdown-cafecito" className="dropdown-cafecito-content">
        <button
          className="price-cafecito"
          onClick={() => buttonGenerator("235896449-fcc76017-8bec-4733-88fd-a3e4eb1b636e", "30")}
        >
          <i className="fas fa-mug-hot"></i> $30
        </button>
        <button
          className="price-cafecito"
          onClick={() => buttonGenerator("235896449-5a62ca03-819c-4031-a0f2-580ad6a66913", "40")}
        >
          <i className="fas fa-mug-hot"></i> $40
        </button>
        <button
          className="price-cafecito"
          onClick={() => buttonGenerator("235896449-f104aa85-781c-408b-a11b-13cde4a37612", "50")}
        >
          <i className="fas fa-mug-hot"></i> $50
        </button>

        <button
          className="price-cafecito"
          onClick={() => buttonGenerator("235896449-5c6a6715-b198-4204-87a8-a2353163b3f0", "60")}
        >
          <i className="fas fa-mug-hot"></i> $60
        </button>
      </div>
    </div>
  );
}
