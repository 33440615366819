import { useCallback, useEffect, useState } from "react";
import Select from "react-select";

export default function ConteoSelect(props) {
  const [options, setOptions] = useState();
  const [primera, setPrimera] = useState();
  const [segunda, setSegunda] = useState();
  const [defaultMes, setDefaultMes] = useState();
  const [select, setSelect] = useState();
  

  const {dropDowndirection, quincenaDiasList, setFetchingSelect,setMes,setQuincena} = props;

  const handleChangeMes = useCallback((selectedOption) => {
    setMes(selectedOption.value);
    
  },[setMes]);
  const handleChangeQuincena = (selectedOption) => {
    
    setQuincena(selectedOption.target.value);
  };
  useEffect(() => {
    if (quincenaDiasList && quincenaDiasList !== "fetch") {
      setFetchingSelect(true);
      let Mes1 = quincenaDiasList[0][0];
      let mes2 = quincenaDiasList[0][1];
      let mes3 = quincenaDiasList[0][2];
      setDefaultMes({ value: mes2[1], label: mes2[0] });
      setTimeout(() => {
        setOptions([
          {
            value: Mes1[1],
            label: Mes1[0],
          },
          {
            value: mes2[1],
            label: mes2[0],
          },
          {
            value: mes3[1],
            label: mes3[0],
          },
        ]);

        if (quincenaDiasList[1][0] === "1") {
          setSegunda(false);
          setPrimera(true);
          setQuincena("1");
        } else {
          setSegunda(true);
          setPrimera(false);
          setQuincena("2");
        }
        
          
        setMes(mes2[1]);
        setFetchingSelect(false);
      }, 1000);
    }
  }, [quincenaDiasList,setMes,setQuincena,setFetchingSelect]);

  useEffect(() => {
    if (options && defaultMes) {
      let select1 = (
        <Select
          menuPlacement={dropDowndirection}
          defaultValue={defaultMes}
          options={options}
          onChange={handleChangeMes}
          className="select-conteo"
          name=""
          id=""
          isSearchable={false}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: "transparent",
              primary: "#4ca771",
            },
          })}
        />
      );
      setSelect(select1);
    }
  }, [defaultMes, options, quincenaDiasList, dropDowndirection,handleChangeMes]);

  return (
    <div>
      <div id="select-div">
        {select}
        <form onChange={handleChangeQuincena} className="select-conteo">
          <span>
            <input
              defaultChecked={primera}
              name="quincena"
              type="radio"
              id="primera"
              value="1"
            />
            <label htmlFor="primera"> Primera</label>
          </span>
          <span>
            <input
              defaultChecked={segunda}
              name="quincena"
              type="radio"
              id="segunda"
              value="2"
            />
            <label htmlFor="segunda"> Segunda</label>
          </span>
        </form>
       
      </div>
    </div>
  );
}
