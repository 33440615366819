import { useState } from "react";
export default function SueldoAprox(props) {
  const [reciboToggler, setreciboToggler] = useState(false);
  const [reciboData, setReciboData] = useState(false);
  const [hasPresentismo, setHasPresentismo] = useState(false);
  const [hasCategoria, setHasCategoria] = useState(30);
  const [hasAntiguedad, setHasAntiguedad] = useState(0);
  const [precioHora, setPrecioHora] = useState(304.84);
  const close = (id) => {
    document.getElementsByTagName("html")[0].classList.remove("disable-scroll");
    if (id === "recibo") {
      setreciboToggler(false);
      return;
    }
    document.getElementById(id).classList.toggle("d-none");
    
    
  };

  const getRecibo = (data) => {
    fetch(props.mainURL+"/get_sueldo.php", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("fail to fetch data from resource");
        }

        return res.json();
      })
      .then((data) => {
        setReciboData(data);
        setreciboToggler(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAndShowRecibo = () => {
    let data = {
      mes: props.mes,
      quincena: props.quincena,
      local: props.user.local,
      user: props.user,
    };
    data.antiguedad = hasAntiguedad;
    data.presentismo = hasPresentismo;
    data.categoria = hasCategoria;
    data.precio_hora = precioHora;
    getRecibo(data);

    close("sueldo-aprox");
    document.getElementsByTagName("html")[0].classList.add("disable-scroll");
  };

  const handleChanges = (item) => {
    item = item.target;
    if (item.name === "preciohora") {
      // console.log(item.value);
      setPrecioHora(item.value);
    } else if (item.name === "presentismo") {
      // console.log(item.checked);
      setHasPresentismo(item.checked);
    } else if (item.name === "categorita-input") {
      // console.log(item.value);
      setHasCategoria(item.value);
    } else if (item.name === "antiguedad") {
      // console.log(item.value);
      setHasAntiguedad(item.value);
    }
    // console.log(item);
  };
  const Recibo = () => {
    let aumentos = reciboData.aumentos;
    let descuentos = reciboData.descuentos;
    let totales = reciboData.totales;
    const AumentosTable = () => {
      let AumentoPresentismo, AumentoAntiguedad;

      if (aumentos.presentismo[0] === 0 && aumentos.antiguedad[0] === 0) {
        return <div></div>;
      }
      if (aumentos.presentismo[0] !== 0) {
        AumentoPresentismo = () => (
          <tr>
            <td id="aumento-presentismo" className="dashed-left">
              Presentismo
            </td>
            <td>{aumentos.presentismo[0]}%</td>

            <td>${aumentos.presentismo[1]}</td>
          </tr>
        );
      }
      if (aumentos.antiguedad[0] !== 0) {
        AumentoAntiguedad = () => (
          <tr>
            <td id="aumento-antiguedad" className="dashed-left">
              Antiguedad
            </td>
            <td>{aumentos.antiguedad[0]}a</td>

            <td>${aumentos.antiguedad[1]}</td>
          </tr>
        );
      }
      return (
        <table className="table-middle content-section d-table mt-1">
          <tbody>
            <tr id="aumentos" className="">
              <td className="rowspan" rowSpan="3">
                Aumentos
              </td>
            </tr>
            {AumentoPresentismo && <AumentoPresentismo />}
            {AumentoAntiguedad && <AumentoAntiguedad />}
          </tbody>
        </table>
      );
    };
    return (
      <div id="recibo" className=" modal ">
        <div className="modal-content text-center max-width">
          <div className="sub-modal text-left tables-sueldo">
            <h3 className="title-modal">Sueldo Aproximado </h3>
            <table className="table-start content-section d-table">
              <thead>
                <tr></tr>
              </thead>
              <tbody>
                <tr className="">
                  <td className="rowspan" rowSpan="3">
                    Horas
                  </td>
                </tr>
                <tr>
                  <td className="dashed-left">Normales</td>
                  <td>{aumentos.horas_trabajadas[0]}h</td>

                  <td>${aumentos.horas_trabajadas[1]}</td>
                </tr>
                <tr>
                  <td className="dashed-left">nocturnas</td>
                  <td>{aumentos.nocturnas[0]}h</td>

                  <td>${aumentos.nocturnas[1] + " + $" + aumentos.nocturnas[2]}</td>
                </tr>
              </tbody>
            </table>
            <AumentosTable />
            <table className="table-middle content-section d-table">
              <tbody>
                <tr className="">
                  <td className="rowspan" rowSpan="9">
                    Descuentos
                  </td>
                </tr>
                <tr>
                  <td className="dashed-left">inssjp</td>
                  <td>{descuentos.inssjp[0]}%</td>

                  <td>${descuentos.inssjp[1]}</td>
                </tr>
                <tr>
                  <td className="dashed-left">Jubilacion</td>
                  <td>{descuentos.jubilacion[0]}%</td>

                  <td>${descuentos.jubilacion[1]}</td>
                </tr>
                <tr>
                  <td className="dashed-left">Obra social</td>
                  <td>{descuentos.obra_social[0]}%</td>

                  <td>${descuentos.obra_social[1]}</td>
                </tr>
                <tr>
                  <td className="dashed-left">Seguro salud</td>
                  <td>{descuentos.anssal[0]}%</td>

                  <td>${descuentos.anssal[1]}</td>
                </tr>
                <tr>
                  <td className="dashed-left">Sindicato</td>
                  <td>{descuentos.sindicato[0]}%</td>

                  <td>${descuentos.sindicato[1]}</td>
                </tr>
                <tr>
                  <td className="dashed-left">Sepelio</td>
                  <td>{descuentos.sepelio[0]}%</td>

                  <td>${descuentos.sepelio[1]}</td>
                </tr>
                <tr>
                  <td className="dashed-left">rec y tur</td>
                  <td>{descuentos.recytur[0]}%</td>

                  <td>${descuentos.recytur[1]}</td>
                </tr>
                <tr>
                  <td className="dashed-left">mutual</td>
                  <td>{descuentos.mutual[0]}%</td>

                  <td>${descuentos.mutual[1]}</td>
                </tr>
              </tbody>
            </table>

            <table className="table-end content-section d-table">
              <tbody>
                <tr>
                  <td className="rowspan" rowSpan="4">
                    Totales
                  </td>
                </tr>
                <tr>
                  <td className="dashed-left">Bruto</td>

                  <td>${totales.bruto}</td>
                </tr>
                <tr>
                  <td className="dashed-left">Descuentos</td>

                  <td>${totales.descuentos}</td>
                </tr>
                <tr>
                  <td className="dashed-left">Neto</td>

                  <td>${totales.neto}</td>
                </tr>
              </tbody>
            </table>
            <div className="content-section d-flex">
              <button className="button-medium bg-black ml-auto" onClick={() => close("recibo")}>
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {reciboToggler && <Recibo />}
      <div id="sueldo-aprox" className="d-none modal">
        <div className="modal-content text-center max-width">
          <div className="sub-modal">
            <h3 className="title-modal">Configura los datos de tu recibo</h3>
            <div className="text-left">
              <label className="content-section d-flex">
                <h4 className="mr-auto">Precio por hora</h4>
                <input
                  className="small-input"
                  onChange={(e) => handleChanges(e)}
                  placeholder={"$" + precioHora}
                  type="number"
                  name="preciohora"
                />
              </label>
              <label className="content-section d-flex">
                <h4 className="mr-auto">Antiguedad</h4>
                <input
                  className="small-input"
                  onChange={(e) => handleChanges(e)}
                  placeholder={hasAntiguedad + " Años"}
                  type="number"
                  name="antiguedad"
                />
              </label>
              <label className="content-section  d-flex" htmlFor="presentismo-input">
                <h4 className="mr-auto">Presentismo </h4>
                <input
                  className="ml-auto "
                  onChange={(e) => handleChanges(e)}
                  type="checkbox"
                  name="presentismo"
                  id="presentismo-input"
                />
              </label>
              <label htmlFor="categorita-input" className="content-section d-flex">
                <h4 className="mr-auto">Categoria</h4>
                <select onChange={(e) => handleChanges(e)} name="categorita-input" id="categorita-input">
                  <option value="30">Part time</option>
                  <option value="80">Cat 80</option>
                  <option value="85">Cat 85</option>
                  <option value="95">Cat 95</option>
                </select>
              </label>
            </div>
            <div className="content-section d-flex">
              <button className="button-medium bg-black mr-auto" onClick={() => close("sueldo-aprox")}>
                Cerrar
              </button>
              <button className="button-medium bg-primary" onClick={() => getAndShowRecibo()}>
                Mostrar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
