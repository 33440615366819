import Cookie from "universal-cookie";
import { useState } from "react";
function ModalUser(props) {
  let user = props.user;
  let username = user.name;
  let legajo = user.accountLegajo;
  let local = user.local;
  let startPage = user.startPage;
  let [fetchedSuccess, setFetchedSuccess] = useState(false);
  const closeModal = (e) => {
    if (e.target.id === "user-modal") props.setModalToggler(undefined);
    
    document.getElementsByTagName("html")[0].classList.remove("disable-scroll");
  };
  const changeStartPage = (e) => {
    var cookie = new Cookie();
    cookie.set("startPage", e.target.value, { maxAge: 2592000 });
    changePorpietes("homepage", e.target.value);
  };

  function changePorpietes(propToChange, newValue) {
    
    setFetchedSuccess("fetching");
    fetch("https://api.asignaciones.com.ar/userModifications.php", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ propToChange, newValue, local, user }),
    })
      .then((res) => {
        if (!res.ok) {
          setFetchedSuccess('error');
          throw Error("fail to fetch data from resource");
        }
        return res.json();
      })
      .then((data) => {
        setFetchedSuccess(true);
        console.log(data);
      })
      .catch((err) => {
        setFetchedSuccess('error');
      });
  }

  return (
    <div
      onClick={(e) => {
        closeModal(e);
      }}
      id="user-modal"
      className="modal"
    >
      <div id="user-config">
        <span className="config-title">
          <h4>
            <i className="fas fa-cog"></i> configuración
          </h4>
        </span>
        <span className="config-item-container">
          <p className="leftTextAccount"> Legajo: </p> <p className="rightTextAccount">{legajo}</p>
        </span>
        <span className="config-item-container">
          <p className="leftTextAccount"> Usuario:</p> <p className="rightTextAccount">{username}</p>
        </span>

        <span className="config-item-container">
          <p className="leftTextAccount"> Local:</p> <p className="rightTextAccount">{local}</p>
        </span>

        <span className="config-item-container d-none">
          <p className="leftTextAccount">Modo oscuro:</p>
          <label className="switch rightTextAccount">
            <input type="checkbox" />
            <span className="slider"></span>
          </label>
        </span>
        <span className="config-item-container">
          <p className="leftTextAccount"> Pagina inicio: </p>
          <select
            defaultValue={startPage}
            onChange={(e) => changeStartPage(e)}
            name="inicio"
            id="start-page"
            className={
              (fetchedSuccess !== "fetching" ? "fetched" : "fetching") +
              " " +
              fetchedSuccess +
              " rightTextAccount button-medium bg-primary"
            }
            // className={fetchedSuccess ? "active" : "unactive"}
          >
            <option value="inicio">Inicio</option>
            <option value="asignaciones">Asignaciones</option>
            <option value="graficas">Grafica</option>
            <option value="conteo">Conteo</option>
          </select>
        </span>
        <span className="config-item-container d-none">
          <p className="leftTextAccount"> Recuperar Contraseña:</p>{" "}
          <p className="rightTextAccount">
            <button onClick={props.sendResetPassword} id="reset-password-btn">
              Reset password
            </button>
          </p>
        </span>
        <span className="config-item-container">
          <p className="leftTextAccount"></p>{" "}
          <p className="rightTextAccount">
            <button onClick={props.logout} id="reset-password-btn">
              Cerrar sesión
            </button>
          </p>
        </span>
      </div>
    </div>
  );
}

export default ModalUser;
