import { useState, useEffect, useCallback } from "react";
import Cafecito from "../../global/cafecito/StartPageCafecito";
import Cookie from "universal-cookie";
import Updates from "./subcomponents/Updates";

export default function StartPage(props) {
  let [asignacion, setAsignacion] = useState([]);
  let [legajo, setLegajo] = useState("");
  let [error, setError] = useState();
  let [loading, setLoading] = useState(false);
  const[lastUpdate, setLastUpdate] = useState();
  const [CP, setCP] = useState("CP=BK&");
  var globalCookie = new Cookie();

  document.title = "Inicio - " + props.title;
  let cookieLegajo = globalCookie.get("start-page-legajo");
  useEffect(() => {
    setLegajo({ legajo: cookieLegajo });
  }, [cookieLegajo]);
// useEffect(()=> {

// })
  const ClickLegajo = () => {
    return;
  };
  const genTableBody = useCallback((data, err = false) => {
    let tdClass;
    let items = [];
    for (let index in data.asignaciones) {
      let asignacion = data.asignaciones[index];
      let dia = asignacion.fecha;
      let totalHoras = calcHours(asignacion, dia);
      if (totalHoras < 5.5) tdClass = "";
      if (totalHoras > 5.5) tdClass = "plus-five-and-half";
      if (totalHoras > 8) tdClass = "plus-eight";

      let item = (
        <tr onClick={(e) => ClickLegajo(e)} key={index}>
          <td>{asignacion.fecha}</td>
          <td>{asignacion.horaEntrada}</td>
          <td>{asignacion.horaSalida}</td>

          <td className={tdClass}>{totalHoras}</td>
          <td>{asignacion.tienda}</td>
        </tr>
      );
      items.push(item);
    }
    setAsignacion(items);

    if ((data.asignaciones.length = 0)) {
      err = "no asignaciones";
      return;
    }
  }, []);
  


  const calcHours = (asignacion, dia) => {
   
    let d = new Date();
    let horaE = asignacion.horaEntrada;
    let horaS = asignacion.horaSalida;
    dia = dia.slice(-5);

    dia = dia.split("/");

    dia = dia[1] + "/" + dia[0];
    console.log(dia);
    let dateEntrada = Date.parse(dia + "/" + d.getFullYear() + " " + horaE + ":00");
    let dateSalida = Date.parse(dia + "/" + d.getFullYear() + " " + horaS + ":00");
    console.log(dateEntrada)
    let totalHoras = dateSalida - dateEntrada;
    if (dateSalida - dateEntrada < 0) dateSalida = dateSalida + 1000 * 60 * 60 * 24;

    totalHoras = (dateSalida - dateEntrada) / (1000 * 60 * 60);
    return totalHoras;
  };

  const submitHandler = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    setLoading(true);

    let timeoutId = setTimeout(() => {
      fetch(props.mainURL+"/start.php?" + CP + "legajo=" + legajo.legajo, {
        method: "GET",
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (data === "NoLegajo") {
            setError(
              <div>
                <p>Ingresa tu legajo para ver tus horarios.</p>
              </div>
            );
            setLoading(false);
            return;
          }
          if (data.legajo === undefined) {
            setError(
              <div>
                <p>Ingresa tu legajo para ver tus horarios.</p>
              </div>
            );
            setLoading(false);
            return;
          } else if (data.asignaciones.length === 0) {
            setError(
              <div>
                <p> Este legajo no tiene graficas!</p>
                <p> Proba otro legajo</p>
              </div>
            );
            setLoading(false);
            return;
          } else {
            var cookie = new Cookie();
            cookie.set("start-page-legajo", legajo.legajo, { maxAge: 2592000, sameSite: "lax" });
          }

          setLoading(false);
          setError(false);
         if(data.status === "alsea_down"){
           setLastUpdate(data.fechaConsulta);
         }
          genTableBody(data);
        })
        .catch((err) => {
          setError(
            <div>
              <p> Error obteniendo graficas!</p>
              <p> Quizas alsea no esta funcionando?.</p>
            </div>
          );
          setLoading(false);
          setAsignacion([], true);
          console.error(err);
        });
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [legajo, genTableBody, CP,props.mainURL]);


  // useEffect(() => {
  //    let data = {"asignaciones":[{"fecha":"VIERNES 04 12","horaEntrada":"20:00","horaSalida":"01:00","tienda":"28 - BK Villa Urquiza"},{"fecha":"SABADO 05 12","horaEntrada":"20:00","horaSalida":"01:00","tienda":"28 - BK Villa Urquiza"},{"fecha":"MARTES 08 12","horaEntrada":"20:00","horaSalida":"01:00","tienda":"28 - BK Villa Urquiza"}],"fechaConsulta":"04 12 2020 18:39","legajo":"28907"};
  //  genTableBody(data);
  // },[])
 




  const changeCP = () => {
    let CPChanger = document.getElementById("changeCP");
    if (CP === "CP=BK&") {
      setCP("CP=SB&");
      CPChanger.innerHTML = "<p>Legajos</p>  <p>Starbucks</p> ";
    } else {
      setCP("CP=BK&");
      CPChanger.innerHTML = "<p>Legajos</p> <p>BurgerKing</p>";
    }
  };

  return (
    <div className="content">
      <div>
        <div className={loading ? "loading-conteo show" : "loading-conteo hide"}>
          <div className="container-conteo">
            <div className="loading__letter">c</div>
            <div className="loading__letter">a</div>
            <div className="loading__letter">r</div>
            <div className="loading__letter">g</div>
            <div className="loading__letter">a</div>
            <div className="loading__letter">n</div>
            <div className="loading__letter">d</div>
            <div className="loading__letter">o</div>
            <div className="loading__letter">.</div>
            <div className="loading__letter">.</div>
          </div>
        </div>
        <form className="inputLegajo mb-2 mt-2 d-flex" onSubmit={submitHandler}>
          <input
            autoFocus
            onChange={(e) => setLegajo({ ...legajo, legajo: e.target.value })}
            placeholder={""}
            type="text"
          />
          <label>Legajo {legajo.legajo}</label>
          <div className="ml-auto">
            <button type="button" className="button-medium bg-primary" id="changeCP" onClick={() => changeCP()}>
              <p>Legajos</p> <p>BurgerKing</p>
            </button>
          </div>
        </form>
        {lastUpdate && (<div className="text-center bg-red p-1 mb-1 br-1 "> <span>ultima actualizacion:</span> <span>{lastUpdate}</span> <small className="d-block">Error recibiendo data de alsea</small></div>)}
        {!error ? (
          <div>
            <table className="tableAsig fade-in">
              <thead>
                <tr>
                  <th>Dia</th>
                  <th>Entrada</th>
                  <th>Salida</th>
                  <th>Horas</th>
                  <th>Local</th>
                </tr>
              </thead>

              <tbody>{asignacion}</tbody>
            </table>
          </div>
          
        ) : (
          <div className="start-page-err">{error}</div>
        )}

        <div className="mt-2 startNews">
          <Cafecito hideUpdateDetails={props.hideUpdateDetails} />
          <div className="mt-1 mb-1"></div>
          <Updates hideUpdateDetails={props.hideUpdateDetails} />
        </div>
      </div>
    </div>
  );
}
