import React from "react";
import NamesThatHelped from "./cafecito/NamesThatHelped";

export default function Header(props) {
  return (
    <div id="header" className="header">
      <h4 className="">ASIGNACIONES.COM.AR - THTST  <small>{props.version} </small>
      <p className="bg-red p-1">~ Hay algunos problemas con la app, porfavor chequeen las graficas de papel hasta que pueda solucionar los problemas. ~</p></h4>
      <NamesThatHelped mainURL={props.mainURL} hideUpdateDetails={props.hideUpdateDetails} />
    </div>
  );
}
