import { useState } from "react";

import Cookie from "universal-cookie";
const LoginForm = ({ setUser, getDbData, getQuincenalist, title, hideUpdateDetails, mainURL }) => {
  document.title = "Iniciar sesion - " + title;

  let nameErr = false;
  let localErr = false;
  let passErr = false;

  const [error, setError] = useState({
    password: false,
    name: false,
    local: false,
  });
  const [details, setDetails] = useState({
    name: "",
    password: "",
    local: "",
  });

  const [authErr, setAuthErr] = useState(false);

  const [fetching, setFetching] = useState(false);

  const preLogin = (details) => {
    setAuthErr(false);
    if (details.local === "") localErr = "Selecciona tu local";
    if (details.password === "") passErr = "Ingresa tu contraseña";
    if (details.name === "") nameErr = "Ingresa tu usuario";
    setError({ password: passErr, name: nameErr, local: localErr });

    if (nameErr || localErr || passErr) {
    } else {
      Login(details);
    }
  };

  const Login = (details) => {
    setFetching(true);
    fetch(mainURL + "/loginCheck.php", {
      method: "POST",
      headers: { "Content-Type": "application/jsn" },
      body: JSON.stringify(details),
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("fail to fetch data from resource");
        }
        return res.json();
      })
      .then((data) => {
        if (data.usernameerr || data.passworderr || data.localerr) {
          setAuthErr(true);
          setFetching(false);
          return;
        }
        setFetching(false);

        var cookie = new Cookie();
        cookie.set("userid", data[3], { maxAge: 2592000, sameSite: "lax" });
        cookie = new Cookie();
        cookie.set("local", details.local, { maxAge: 2592000, sameSite: "lax" });
        cookie = new Cookie();
        cookie.set("username", details.name, { maxAge: 2592000, sameSite: "lax" });
        cookie = new Cookie();
        cookie.set("logged", true, { maxAge: 2592000, sameSite: "lax" });
        cookie = new Cookie();
        cookie.set("accountLegajo", data[2], { maxAge: 2592000, sameSite: "lax" });
        cookie = new Cookie();
        cookie.set("startPage", data[4], { maxAge: 2592000, sameSite: "lax" });
        setUser({
          name: details.name,
          id: data[3],
          local: details.local,
          logged: true,
          accountLegajo: data[2],
        });
      })
      .catch((err) => {
        setFetching(false);
        if (err.name === "AbortError") {
          console.log("fetch aborted");
        } else {
        }
      });
  };
  const dismissAuthErr = () => {
    setAuthErr(false);
  };
  const submitHandler = (e) => {
    e.preventDefault();
    preLogin(details);
  };




  return (
    <div className="content">
      <div id="modal-register-toggler" className="show-hidden-cont">
        <h3 className="d-inline-block">No tenés cuenta? </h3>
        <button
          className="register-button d-inline-block bs-1"
          onClick={(e) => hideUpdateDetails("modal-register", e, "Registrate!", "Ocultar", "200")}
        >
          Registrate!.
        </button>
        <div id="modal-register" className="shown hidden">
          <h2 className="text-center">
            No podras crear una cuenta si tu local no tiene un administrador y sistema interno
          </h2>
          <h3 className="text-center">Por el momento solo Villa urquiza y san miguel 2 tienen acceso.</h3>
          <p>
            Para registrarte envía un mail a{" "}
            <a href="mailto:cuentas@asignaciones.com.ar">cuentas@asignaciones.com.ar</a> con los siguientes datos:
          </p>
          <ol>
            <li>Legajo</li>
            <li>Nombre: Tu nombre y apellido.</li>
            <li>Local: Tu local principal (si vas a cubrir horas no podes solicitar cuenta para ese local).</li>

            <li>
              E-mail: Si necesitas que tu cuenta se vincule con otro mail, que no es desde el cual enviás el mail.
            </li>
          </ol>
          <p>
            Las cuentas van a ser creadas manualmente por el administrador del local al que pertenezcas, tenenos
            paciencia.
          </p>
        </div>
      </div>
      <h2>Ingresa!</h2>
      <form id="loginForm" onSubmit={submitHandler}>
        <div className="form-group">
          <select
            className="login-select"
            onChange={(e) => setDetails({ ...details, local: e.target.value })}
            value={details.local}
          >
            <option id="disabled-option-login" defaultValue={true} value="empty">
              Local{" "}
            </option>
            <option value="urquiza">Urquiza</option>
            <option value="sanmiguel2">Sanmiguel2</option>
          </select>
          {error.local && (
            <div className="localErr">
              <p>{error.local}</p>
            </div>
          )}
        </div>

        <div className="form-group">
          <label htmlFor="name"> Usuario </label>
          <input
            type="text"
            name="name"
            id="name"
            onChange={(e) => setDetails({ ...details, name: e.target.value.toLocaleLowerCase() })}
            value={details.name}
          />
          {error.name && (
            <div className="userErr">
              <p>{error.name}</p>
            </div>
          )}
        </div>

        <div className="form-group">
          <label htmlFor="password">Contraseña </label>
          <input
            type="password"
            name="password"
            id="password"
            onChange={(e) => setDetails({ ...details, password: e.target.value })}
            value={details.password}
          />
          {error.password && (
            <div className="passErr">
              <p>{error.password}</p>
            </div>
          )}
        </div>
        <div>
          {authErr && (
            <div onClick={dismissAuthErr} className="authErr">
              <p>Algun detalle es incorrecto.</p>
            </div>
          )}
          {fetching && (
            <div className="ingresando">
              <p>Ingresando...</p>
            </div>
          )}
        </div>
        <div id="loginButton">
          <button className="bs-1">Iniciar Sesión</button>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
