import React, { useState, useEffect } from "react";
export default function NamesThatHelped(props) {
  const [arrayThatHelped, setArrayThatHelped] = useState();
  const [phrases, setPhrases] = useState();

  useEffect(() => {
    fetch(props.mainURL+"/cafecito_api.php?getNames=true", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data) {
          setArrayThatHelped(data);
          
          setPhrases([
            "Hoy nuestro esponsor es -X-.",
            "-X- se merece un abrazo.",
            "-X- ayuda al desarrollo de la pagina.",
            "Hoy ves tus horarios gracias a -X-.",
            "Si no fuese por -X- se cerraba el chiringuito.",
            "Se puede contar con -X-.",
            "Denle finde off a -X-.",
            "-X- tu me escuchas, -X- Tu me miras, -X- di si puedes escuchar...",
            "-X- se merece mas horas.",
          ]);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [props.mainURL]);
  // var arrayThatHelped = ["Lucas M", "Santiago A"];
  useEffect(() => {
    if (arrayThatHelped && phrases) {
      shuffleArray(arrayThatHelped);
      shuffleArray(phrases);
    }
  }, [arrayThatHelped, phrases]);
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
  };

  const SelectPhrase = () => {
    let rex = new RegExp("-X-", 'g');
    let donatorName = "<p class='donator-name d-inline'>" + arrayThatHelped[0] + "</p>";
    let phrase = phrases[0].replace(rex, donatorName);
    return <span dangerouslySetInnerHTML={{ __html: phrase }}></span>;
  };

  const Lista = () => {
    let i = 0;
    return (
      <ul className="text-left row">
        {arrayThatHelped.map((e) => {
          i++
          return <li key={i} className="column"> {e} </li>;
          
        })}
      </ul>
    );
  };

  return (
    <span>
      <div className="text-center phrase">
        {arrayThatHelped && phrases && <SelectPhrase />}
        <span id="chevron-open-list"
          className="button-small  bg-primary ml-1 mt-1"

          onClick={(e) => props.hideUpdateDetails("names-that-helped-list", e, '<i class="fas fa-arrow-down"></i>','<i class="fas fa-arrow-up"></i>', "100" ,"chevron-open-list" )}
        >
          <i className="fas fa-arrow-down"></i>
        </span>
        <div className="show-hidden-cont">
          <div id="names-that-helped-list" className="shown hidden">
            {arrayThatHelped && phrases && <Lista />}
          </div>
        </div>
      </div>
    </span>
  );
}
