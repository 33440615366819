import { NavLink } from "react-router-dom";
export default function NavBarStart() {
  return (
    <div className="navbar-start-pre">
      <nav className="navbar-start">
        <NavLink className="btn border-primary bg-transparent" activeClassName="navbar-hide" exact to="/">
          <div>
            <p className="btn-text">Inicio</p>
          </div>
        </NavLink>

        <NavLink className="btn border-primary bg-transparent" activeClassName="navbar-hide" exact to="/login">
          <div>
            <p className="btn-text">Iniciar sesión</p>
          </div>
        </NavLink>
      </nav>
      
    </div>
  );
}
