
import { NavLink, useHistory } from "react-router-dom";
import {useEffect} from "react";

const NavBar = (props) => {
  let history = useHistory();
  const accountModalToggle = () => {
    if (props.modalToggler) {
      props.setModalToggler(undefined);
      
    document.getElementsByTagName("html")[0].classList.remove("disable-scroll");
    } else {
      props.setModalToggler(true);
      window.scrollTo(0, 0);
    document.getElementsByTagName("html")[0].classList.add("disable-scroll");
    }
  };
  useEffect(() => {
    if (
      props.user.startPage === "asignaciones" ||
      props.user.startPage === "graficas" ||
      props.user.startPage === "conteo"
    ) {
      if (!props.isFirstRender && history.location.pathname === "/") {
        props.setIsFirstRender(true);
        history.push("/" + props.user.startPage);
      }
    }
    
  })
  
  return (
    <nav className="navbar">
      <NavLink className="btn" activeClassName="navbar__link--active" exact to="/">
        <div>
          <i className="fas fa-home navbarIcons"></i>
          <p className="btn-text"> Inicio</p>
        </div>
      </NavLink>
      <NavLink exact className="btn" activeClassName="navbar__link--active" to="/asignaciones">
        <div>
          <i className="fas fa-align-justify navbarIcons"></i>
          <p className="btn-text">Asignaciones</p>
        </div>
      </NavLink>

      <NavLink exact className="btn" activeClassName="navbar__link--active" to="/graficas">
        <div>
          <i className="fas fa-th-list navbarIcons"></i>
          <p className="btn-text">Grafica</p>
        </div>
      </NavLink>
      <NavLink exact className="btn" activeClassName="navbar__link--active" to="/conteo">
        <div>
          <i className="fas fa-calculator navbarIcons"></i>
          <p className="btn-text">Conteo</p>
        </div>
      </NavLink>
      <span className="btn" onClick={accountModalToggle}>
        <i className="fas fa-user"></i>
        <p className="btn-text">Cuenta</p>
      </span>
    </nav>
  );
};

export default NavBar;
