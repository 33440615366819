import { useState, useEffect } from "react";

const Grafica = (props) => {
  useEffect(() => {
    document.title = "Grafica - " + props.title;
  }, [props.title]);

  const [buttons1, setButtons1] = useState();
  const [selectedGrafica, setSelectedGrafica] = useState();
  const [francosGrafica, setFrancosGrafica] = useState();
  const [otrosLocalesGrafica, setOtrosLocalesGrafica] = useState();

  useEffect(() => {
    const horariosOC = {
      LUNES: ["07", "02","01"],
      MARTES: ["07", "02","01"],
      MIERCOLES: ["07", "02","01"],
      JUEVES: ["07", "02","01"],
      VIERNES: ["07", "03","02"],
      SABADO: ["08", "03","02"],
      DOMINGO: ["09", "03","02"],
    };
    let firstFade = false;
    let selectedLegajos = [];

    const hourCounting = (dia, data) => {
      let d = new Date();
      let totalHoras;
      if (data.horaentrada === "OFF") {
        totalHoras = 0;
      } else {
        let dateEntrada = Date.parse(dia.slice(-5) + "/" + d.getFullYear() + " " + data.horaentrada);

        let dateSalida = Date.parse(dia.slice(-5) + "/" + d.getFullYear() + " " + data.horasalida);
        if (dateSalida - dateEntrada < 0) {
          dateSalida = dateSalida + 1000 * 60 * 60 * 24;
        }
        totalHoras = (dateSalida - dateEntrada) / (1000 * 60 * 60);
      }
      return totalHoras;
    };

    const genOtrosLocalesGrafica = (
      data,
      dia,
      selectedLegajos,
      selectLegajo,
      francos,
      grafica,
      arrayOtrosLocales,
      otrosLocales
    ) => {
      let newArray = [];
      for (let local in data) {
        let trClass;
        let tdClass;

        var item2 = (
          <tr className={trClass} key={local}>
            <td colSpan="2">
              {local} <i className="fas fa-arrow-down"></i>
            </td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        );
        newArray.push(item2);
        for (let client in data[local]) {
          client = data[local][client];
          let totalHoras = hourCounting(dia, client);

          if (data.legajo === props.userData.accountLegajo) {
            trClass = "account";
          }
          if (selectedLegajos.includes(data.legajo)) {
            trClass = "selected-legajos-graf";
          }
          if (totalHoras < 5.5) tdClass = "";
          if (totalHoras >= 5.5) tdClass = "plus-five-and-half";
          if (totalHoras >= 8) tdClass = "plus-eight";

          var item = (
            <tr onClick={(e) => selectLegajo(e)} className={trClass} key={client.legajo}>
              <td>{client.nombre}</td>
              <td>{client.horaentrada}</td>
              <td>{client.horasalida}</td>
              <td>{client.legajo}</td>
              <td className={tdClass}>{totalHoras}</td>
            </tr>
          );
          newArray.push(item);
        }
      }
      return newArray;
    };

    const genTrs = (
      data,
      dia,
      selectedLegajos,
      selectLegajo,
      francos,
      grafica,
      arrayOtrosLocales,
      otrosLocales,
      diaName
    ) => {
      let totalHoras = hourCounting(dia, data);

      if (data.legajo !== undefined) {
        let trClass;
        let tdClass;
        let HSClass;
        let HEClass;
        diaName = diaName.split(" ")[0];
        if (data.legajo === props.userData.accountLegajo) {
          trClass = "account";
        }
        if (selectedLegajos.includes(data.legajo)) {
          trClass = "selected-legajos-graf";
        }
    
        
        if (horariosOC[diaName][0] === data.horaentrada.substring(0, 2)) {
          HEClass = HEClass + " apertura";
        }else if(horariosOC[diaName][1] === data.horasalida.substring(0, 2)) {
          HSClass = HSClass + " cierre";
        }else if(horariosOC[diaName][2] === data.horasalida.substring(0, 2)){
          HSClass = HSClass +" precierre"
        }

       

        
        if (totalHoras < 5.5) tdClass = "";
        if (totalHoras >= 5.5) tdClass = "plus-five-and-half";
        if (totalHoras >= 8) tdClass = "plus-eight";

        var item = (
          <tr onClick={(e) => selectLegajo(e)} className={trClass} key={data.legajo}>
            <td>{data.nombre}</td>
            <td className={HEClass}>{data.horaentrada}</td>
            <td className={HSClass}>{data.horasalida}</td>
            <td>{data.legajo}</td>
            <td className={tdClass}>{totalHoras}</td>
          </tr>
        );

        if (data.horaentrada === "OFF") {
          francos.unshift(item);
        } else if (data.tienda.toLowerCase().replace(/\s/g, "").includes(props.userData.local.toLowerCase())) {
          grafica.unshift(item);
        }
      }
    };

    const selectLegajo = (data) => {
      let trselect = data.target.parentElement;
      let legajo = data.target.parentElement.childNodes[3].textContent;

      if (legajo === props.userData.accountLegajo) {
        trselect.classList.add("sameLegajoSelection");
        trselect.classList.remove("account");
        setTimeout(() => {
          trselect.classList.remove("sameLegajoSelection");
          trselect.classList.add("account");
        }, 480);
        return;
      }
      if (selectedLegajos.includes(legajo)) {
        for (var i = 0; i < selectedLegajos.length; i++) {
          if (selectedLegajos[i] === legajo) {
            selectedLegajos.splice(i, 1);
            i--;
          }
          trselect.classList.remove("selected-legajos-graf");
        }
      } else {
        selectedLegajos.push(legajo);
        trselect.classList.add("selected-legajos-graf");
      }
    };

    const buttonsGen = (diaFormated) => {
      let dias = props.dbData.diasList;
      let buttons = [];

      for (let dia in dias) {
        let clase;
        if (dias[dia][1] === diaFormated) {
          clase = "buttonGraficas-selected";
        } else {
          clase = "buttonGraficas";
        }
        var button = (
          <button className={clase} onClick={() => selectGrafica(dias[dia][1], dias[dia][0])} key={dias[dia][1]}>
            {dias[dia][0].substring(0, 1) + " " + dias[dia][0].substring(dias[dia][0].length - 5)}
          </button>
        );
        buttons.push(button);
      }
      setButtons1(buttons);
    };

    const selectGrafica = (diaFormated, diaName) => {
      let table1 = document.getElementsByClassName("table-grafic")[0];
      let table2 = document.getElementsByClassName("table-grafic")[1];
      let table3 = document.getElementsByClassName("table-grafic")[2];
      if (!firstFade) {
        table1.classList.add("fade-in");
        table2.classList.add("fade-in");
        table3.classList.add("fade-in");
      }
      if (firstFade) {
        table1.classList.remove("fade-in");
        table2.classList.remove("fade-in");
        table3.classList.remove("fade-in");
      }
      
      let graficaOBJ = props.dbData.graficas[Object.keys(props.dbData.graficas)[0].split(" ")[0]+ " " + diaFormated];

      let grafica = [];
      let francos = [];
      let otrosLocales = [];
      let arrayOtrosLocales = [];

      let dia = diaFormated[3] + diaFormated[4] + "/" + diaFormated[0] + diaFormated[1];
      dia = dia.split("/");
      dia = dia[1] + "/" + dia[0];

      let otrosLocalesArray = [];
      for (let user in graficaOBJ) {
        user = graficaOBJ[user];
        if (user.hasOwnProperty("tienda")) {
          if (
            user.tienda !== "N/A" &&
            !user.tienda.toLowerCase().replace(/\s/g, "").includes(props.userData.local.toLowerCase())
          ) {
            // genTrs(user, dia, selectedLegajos, selectLegajo, francos, grafica, arrayOtrosLocales, otrosLocales);
            if (!otrosLocalesArray.hasOwnProperty(user.tienda)) {
              otrosLocalesArray[user.tienda] = [];
            }
            otrosLocalesArray[user.tienda].unshift(user);
          } else {
            genTrs(
              user,
              dia,
              selectedLegajos,
              selectLegajo,
              francos,
              grafica,
              arrayOtrosLocales,
              otrosLocales,
              diaName,
            );
          }
        }
      }
      otrosLocales = genOtrosLocalesGrafica(
        otrosLocalesArray,
        dia,
        selectedLegajos,
        selectLegajo,
        francos,
        grafica,
        arrayOtrosLocales,
        otrosLocales
      );

      setSelectedGrafica(grafica);
      setFrancosGrafica(francos);
      setOtrosLocalesGrafica(otrosLocales);
      buttonsGen(diaFormated);
      if (firstFade) {
        table1.classList.add("fast-fade-in");
        table2.classList.add("fast-fade-in");
        table3.classList.add("fast-fade-in");
      }

      firstFade = true;
      setTimeout(function () {
        table1.classList.remove("fast-fade-in");
        table2.classList.remove("fast-fade-in");
        table3.classList.remove("fast-fade-in");
      }, 700);
    };

    buttonsGen();
    if (props.dbData.diasList[0]) {
      selectGrafica(props.dbData.diasList[0][1], props.dbData.diasList[0][0]);
      
    }
  }, [props.dbData, props.userData.accountLegajo, props.userData.local]);


  return (
    <div>
      <div className="divpretable">
        <div className="buttonsGraficasDiv">{buttons1}</div>
      </div>
      <table className="table-grafic fade-in top">
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Entrada</th>
            <th>Salida</th>
            <th>Legajo</th>
            <th>Horas</th>
          </tr>
        </thead>

        <tbody>{selectedGrafica}</tbody>
      </table>
      {otrosLocalesGrafica && otrosLocalesGrafica.length > 0 && (
        <div className="table-title center">
          <h2>
            <i className="fas fa-level-down-alt fa-flip-horizontal"></i> Otros Locales{" "}
            <i className="fas fa-level-down-alt"></i>
          </h2>
        </div>
      )}

      <table className="table-grafic fade-in otros-locales-table middle">
        <tbody>{otrosLocalesGrafica}</tbody>
      </table>

      <div className="table-title center">
        <h2>
          <i className="fas fa-level-down-alt fa-flip-horizontal"></i> Francos <i className="fas fa-level-down-alt"></i>
        </h2>
      </div>
      <table className="table-grafic fade-in francos-table bottom">
        <tbody>{francosGrafica}</tbody>
      </table>
    </div>
  );
};

export default Grafica;
