import React from "react";
import { useEffect } from "react";
export default function Modalpay(props) {
  const close = () => {
    document.getElementById("paymentModal").classList.add("d-none");
  };
  useEffect(() => {
  
    if(document.getElementsByClassName("mercadopago-button")[0]){
      console.log(document.getElementsByClassName("mercadopago-button")[0])
      document.getElementsByClassName("mercadopago-button")[0].innerHTML = "Cafecito por $"+props.value
    }
  },[props.value])
  return (
    <div id="paymentModal" className="modal d-none">
      <div className="modal-content text-center">
        <div>
          <div className="p-relative content-section">
            <h2 className="d-inline">Cafecito por ${props.value}.</h2>
            <div className="p-absolute tr-2">
              
            </div>
          </div>
          <span className="content-section" id="paymentDiv"></span>

          <div className="content-section d-flex">
          <button className="ml-auto button-medium bg-black" onClick={close}>
                Cerrar
              </button>
          </div>
        </div>
      </div>
    </div>
  );
}
