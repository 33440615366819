import { useEffect, useState } from "react";
export default function Modifications(props) {
  const [checked, setChecked] = useState(false);
  // const [hourModif, setHourModif] = useState(false);
  const [horaEntrada, setHoraEntrada] = useState(props.selectionObj.entrada.substring(0, 2));
  const [minEntrada, setMinEntrada] = useState(props.selectionObj.entrada.substring(3, 5));
  const [horaSalida, setHoraSalida] = useState(props.selectionObj.salida.substring(0, 2));
  const [minSalida, setMinSalida] = useState(props.selectionObj.salida.substring(3, 5));
  const [valueNull, setValueNull] = useState(false);
  const [data, setData] = useState();
  useEffect(() => {
    if (
      props.selectionObj.mHoraentrada === "OFF" ||
      (props.selectionObj.oHoraentrada === "OFF" && props.selectionObj.mHoraentrada === null)
    ) {
      setChecked(true);
    } else {
      setChecked(false);
    }
    setHoraEntrada(props.selectionObj.entrada.substring(0, 2));
    setMinEntrada(props.selectionObj.entrada.substring(3, 5));
    setHoraSalida(props.selectionObj.salida.substring(0, 2));
    setMinSalida(props.selectionObj.salida.substring(3, 5));
  }, [props.selectionObj]);

  useEffect(() => {
    
    setData({ horaSalida, minSalida, horaEntrada, minEntrada });
  }, [horaEntrada, horaSalida, minEntrada, minSalida]);
  const hide = () => {
    document.getElementById("hour-modify").classList.toggle("d-none");
  };

 

  const checkHorarioIntegrity = (obj) => {
    let horaE = obj.horaEntrada;
    let horaS = obj.horaSalida;
    let minE = obj.minEntrada;
    let minS = obj.minSalida;
    if (horaE > horaS && horaS > 7) {
      return "Entras antes de salir? chequea los valores ingresados.";
    }
    if (horaE === horaS && minE === minS) {
      return "Entrada y salida tienen los mismos valores.";
    }
    if (!obj.horaEntrada || !obj.horaSalida || !obj.minEntrada || !obj.minSalida) {
      return "Falta agregar algun valor.";
    }
  };

  const save = () => {
    let body = {
      horaentrada: data.horaEntrada + ":" + data.minEntrada,
      horasalida: data.horaSalida + ":" + data.minSalida,
      dia: props.selectionObj.dia,
      user: props.user,
    };

    let error = checkHorarioIntegrity(data);
    if (checked) {
      body.horaentrada = "OFF";
      body.horasalida = "OFF";
      error = false;
    }
    if (error) {
      setValueNull(error);
      return;
    } else {
      setValueNull(false);
    }

    fetch(props.mainURL+"/hour_modifications.php", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("fail to fetch data from resource");
        }
        return res.json();
      })
      .then((body) => {
        props.setUpdate(props.update + 1);
        hide();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //checked off

  useEffect(() => {
    let hourModify = document.getElementById("hour-modify");
    if (checked) {
      hourModify.classList.add("disable-inputs");
    } else {
      hourModify.classList.remove("disable-inputs");
    }
  }, [checked]);
  const handleChange = () => {
    setChecked(!checked);
  };
  const Checkbox = ({ label, value, onChange }) => {
    return (
      <label className="ml-1 mt-1 content-section">
        <input className="mr-1" type="checkbox" checked={value} onChange={onChange} />
        {label}
      </label>
    );
  };

  return (
    <div id="hour-modify" className="modal d-none disable-inputs">
      <div className="modal-content">
        <h3 className="title-modal">Modificar Horarios</h3>
        <div className="sub-modal">
          <div className="content-section">
            {props.selectionObj.dia}
            <div className="ml-1 ">
              {props.selectionObj.entrada} - {props.selectionObj.salida} |{" "}
              <small>
                {" "}
                Original: {props.selectionObj.oHoraentrada} - {props.selectionObj.oHorasalida}
              </small>
            </div>
          </div>
          <form>
            <div className="content-section">
              <label className="d-block mb-1">
                <input
                  value={horaEntrada}
                  onChange={(e) => setHoraEntrada(e.target.value)}
                  className="ml-1 mr-1 mt-1"
                  type="number"
                />
                :
                <input
                  value={minEntrada}
                  onChange={(e) => setMinEntrada(e.target.value)}
                  className="ml-1 mr-1"
                  type="number"
                />{" "}
                Entrada
              </label>
              <label className="d-block">
                <input
                  value={horaSalida}
                  onChange={(e) => setHoraSalida(e.target.value)}
                  className="ml-1 mr-1 mt-1"
                  type="number"
                />
                :
                <input
                  value={minSalida}
                  onChange={(e) => setMinSalida(e.target.value)}
                  className="ml-1 mr-1"
                  type="number"
                />{" "}
                Salida
              </label>
              {valueNull && <div className="error">{valueNull}</div>}
            </div>
            <Checkbox label=" OFF" value={checked} onChange={handleChange} />
          </form>

          <div className="content-section d-flex">
            
            <button className="mr-auto button-medium bg-primary" onClick={() => save()}>
              Guardar
            </button>
            <button className="button-medium bg-black" onClick={() => hide()}>
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
